import { Directive, ElementRef, Renderer2 } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { fromEvent } from 'rxjs';

@UntilDestroy()
@Directive({
  selector: '[appPasswordViasability]',
  standalone: true,
})

export class PasswordVisibilityDirective {
  public visibility: boolean = false;

  private input?: HTMLElement;

  private icon?: HTMLElement;

  private iconPath: string = '/assets/icons/shared-icons/visibility.svg';

  private iconPathOff: string = '/assets/icons/shared-icons/visibility_off.svg';

  constructor(
    private readonly elementRef: ElementRef,
    private readonly renderer: Renderer2,
  ) { }

  ngAfterViewInit(): void {
    this.input = this.elementRef.nativeElement.querySelector('input');
    this.input?.setAttribute('type', 'password');

    this.icon = this.renderer.createElement('img');
    this.icon!.setAttribute('src', this.iconPathOff);

    this.icon!.style.cssText = 'position: absolute;right: 2%;top: 20%;cursor: pointer;';

    const text = document.createTextNode('visibility_off');

    this.icon!.appendChild(text);
    this.renderer.appendChild(this.elementRef.nativeElement, this.icon);

    fromEvent(this.icon!, 'click').pipe(untilDestroyed(this)).subscribe(() => {
      this.toggleVisibility();
    });
  }

  private toggleVisibility(): void {
    this.visibility = !this.visibility;
    if (this.icon) {
      const iconPath = this.visibility ? this.iconPath : this.iconPathOff;
      this.icon.setAttribute('src', iconPath);
    }

    const inputType = this.visibility ? 'text' : 'password';
    this.input?.setAttribute('type', inputType);
  }
}
